export enum StateTypeEnum {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  KS = "KS",
  NM = "NM",
  RI = "RI",
  WY = "WY",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  DC = "DC",
}
export const StateTypeDisplay: { name: string; value: StateTypeEnum }[] = [
  { name: "Alabama", value: StateTypeEnum.AL },
  { name: "Alaska", value: StateTypeEnum.AK },
  { name: "Arizona", value: StateTypeEnum.AZ },
  { name: "Arkansas", value: StateTypeEnum.AR },
  { name: "California", value: StateTypeEnum.CA },
  { name: "Colorado", value: StateTypeEnum.CO },
  { name: "Connecticut", value: StateTypeEnum.CT },
  { name: "Kansas", value: StateTypeEnum.KS },
  { name: "New Mexico", value: StateTypeEnum.NM },
  { name: "Rhode Island", value: StateTypeEnum.RI },
  { name: "Wyoming", value: StateTypeEnum.WY },
  { name: "Delaware", value: StateTypeEnum.DE },
  { name: "Florida", value: StateTypeEnum.FL },
  { name: "Georgia", value: StateTypeEnum.GA },
  { name: "Hawaii", value: StateTypeEnum.HI },
  { name: "Idaho", value: StateTypeEnum.ID },
  { name: "Illinois", value: StateTypeEnum.IL },
  { name: "Indiana", value: StateTypeEnum.IN },
  { name: "Iowa", value: StateTypeEnum.IA },
  { name: "Kentucky", value: StateTypeEnum.KY },
  { name: "Louisiana", value: StateTypeEnum.LA },
  { name: "Maine", value: StateTypeEnum.ME },
  { name: "Maryland", value: StateTypeEnum.MD },
  { name: "Massachusetts", value: StateTypeEnum.MA },
  { name: "Michigan", value: StateTypeEnum.MI },
  { name: "Minnesota", value: StateTypeEnum.MN },
  { name: "Mississippi", value: StateTypeEnum.MS },
  { name: "Missouri", value: StateTypeEnum.MO },
  { name: "Montana", value: StateTypeEnum.MT },
  { name: "Nebraska", value: StateTypeEnum.NE },
  { name: "Nevada", value: StateTypeEnum.NV },
  { name: "New Hampshire", value: StateTypeEnum.NH },
  { name: "New Jersey", value: StateTypeEnum.NJ },
  { name: "New York", value: StateTypeEnum.NY },
  { name: "North Carolina", value: StateTypeEnum.NC },
  { name: "North Dakota", value: StateTypeEnum.ND },
  { name: "Ohio", value: StateTypeEnum.OH },
  { name: "Oklahoma", value: StateTypeEnum.OK },
  { name: "Oregon", value: StateTypeEnum.OR },
  { name: "Pennsylvania", value: StateTypeEnum.PA },
  { name: "South Carolina", value: StateTypeEnum.SC },
  { name: "South Dakota", value: StateTypeEnum.SD },
  { name: "Tennessee", value: StateTypeEnum.TN },
  { name: "Texas", value: StateTypeEnum.TX },
  { name: "Utah", value: StateTypeEnum.UT },
  { name: "Vermont", value: StateTypeEnum.VT },
  { name: "Virginia", value: StateTypeEnum.VA },
  { name: "Washington", value: StateTypeEnum.WA },
  { name: "West Virginia", value: StateTypeEnum.WV },
  { name: "Wisconsin", value: StateTypeEnum.WI },
  { name: "Washington D.C.", value: StateTypeEnum.DC },
];
